<template lang='pug'>
	div
		fieldset.form__section.form__section_bla
			.legend.legend_2 Контактные данные
			.item-inp
				input(
					@input='Input' :value='Answers.fio' @focus='Focus' @blur='Blur'
					name="fio"
					:id="`full-name--${formId}`"
					type="text"
				).input-2
				label(:for="`full-name--${formId}`").label-2 ФИО
			.item-inp
				input(
					@input='Input' :value='Answers.mail' @focus='Focus' @blur='Blur'
					name="mail"
					:id="`email--${formId}`"
					type="text"
				).input-2
				label(:for="`email--${formId}`").label-2 Почта
			.item-inp
				input(
					@input='Input' :value='Answers.phone' @focus='Focus' @blur='Blur'
					name="phone"
					:id="`tel-number--${formId}`"
					type="text"
				).input-2
				label(:for="`tel-number--${formId}`").label-2 Телефон
				span.invalid-span Обязательное поле
			.item-inp(v-if='which === "full-form"')
				textarea(
					@input='Input' :value='Answers.comment' @focus='Focus' @blur='Blur'
					name="comment"
					:id="`project--${formId}`"
					type="text"
				).input-2.input-2__textarea
				label(:for="`project--${formId}`").label-2 Расскажите о своем проекте
			.item-inp(v-if='which === "short-form"')
				textarea(
					@input='Input' :value='Answers.comment' @focus='Focus' @blur='Blur'
					name="comment"
					:id="`question--${formId}`"
					type="text"
				).input-2.input-2__textarea
				label(:for="`question--${formId}`").label-2 Задайте Ваш вопрос
		fieldset.form__section.form__section_whi
			.checkbox-container
				input(
					v-model='Agreement'
					:name="`checkAgree--${formId}`"
					:id="`checkAgree--${formId}`"
					type="checkbox"
				).checkbox-3
				label(:for="`checkAgree--${formId}`" :id='`labelAgree--${formId}`').label-3 Я согласен на обработку моих персональных данных и ознакомился с политикой обработки персональных данных
					.custom-check
			button(type="submit").common-btn.form-comp__submit(@click.prevent="Submit") Отправить
</template>

<script>
export default {
	name: 'formSmall',
	props: {
		formId: {
			type: Number,
			required: true,
		},
		FormPermission: Boolean,
		which: {
			type: String,
			default: 'short-form'
		}
	},
	data() {
		return {
			Answers: {
				title: '',
				fio: '',
				phone: '',
				mail: '',
				comment: '',
				chemZanim: '',
				vChemZadacha: '',
				reshenie: '',
				ogidanie: '',
				money: '',
				fileName: '',
				fileBase64: '',
			},
			Agreement: false,
		}
	},
	methods: {
		Input(e) {
			this.Answers[e.target.getAttribute('name')] = e.target.value
		},
		Focus(e) {
			if(!e.target.classList.contains('input-2--written')) e.target.classList.add('input-2--written')
		},
		Blur(e) {
			if(e.target.value === '') e.target.classList.remove('input-2--written')
		},
		Submit() {
			if(this.formId === 0) {
				eval("ym(83168674,'reachGoal','submit_form_evaluation_project')")
			} else if(this.formId === 1) {
				eval("ym(83168674,'reachGoal','submit_form_write_to_us')")
			}
			if(this.Validation) {
				this.$emit('submiting', this.Answers)
			} else {
				console.error('Invalid form')
			}
		},
	},
	computed: {
		Validation() {
			let TotalValid = true
			if(!this.FormPermission) TotalValid = false
			if(!this.CheckAgreement) TotalValid = false
			if(!this.CheckTel) TotalValid = false
			return TotalValid
		},
		CheckAgreement() {
			if(!this.Agreement) {
				document.querySelector(`#labelAgree--${this.formId}`).classList.add('--invalid')
				return false
			} else { return true }
		},
		CheckTel() {
			if(!this.Answers.phone) {
				document.querySelector(`#tel-number--${this.formId}`).classList.add('--invalid')
				return false
			} else { return true }
		}
	}
}
</script>

<style lang='sass'>
@import './../../assets/styles/__variables.sass'


.form__section
	width: 100%
	margin: 0
	border: none
	appearance: none
	padding: 40px 24px
	@media (max-width: $max_1)
		padding: 20px 24px
	&_bla
		background-color: $bg-black
	&_pt0
		padding-top: 0
	&_whi
		background-color: $bg-white
	&_static
		padding-left: 7%
		padding-right: 7%
		& .label-2
			font-size: 28px
			line-height: 40px
			@media (max-width: $max_1)
				font-size: 18px
				line-height: 20px
		& .input-2
			height: 40px
			@media (max-width: $max_1)
				height: 22px

.invalid-span
	display: none
	color: $red
	font-size: 12px
.item-inp
	position: relative
	padding: 30px 0 10px 0
.label-2
	display: block
	position: absolute
	top: 30px
	cursor: text
	font-weight: 400
	color: $font-white
	transition: all 0.2s

.input-2
	display: block
	width: 100%
	color: $font-white
	font-size: 16px
	background-color: transparent
	appearance: none
	border: none
	border-bottom: 1px solid $bg-white
	caret-color: $button-color
	&:focus
		border-bottom: 1px solid $button-color
	&--written
		& + .label-2
			font-size: 12px
			top: 10px
	&__textarea
		resize: vertical
	&.--invalid
		& ~ .invalid-span
			display: block
.legend
	color: $font-white
	font-size: 20px
	font-weight: 700
	text-transform: uppercase

.label-3
	display: block
	position: relative
	padding-left: 30px
	font-size: 12px
	line-height: 16px
	font-weight: 400
	color: $font-dgrey
	cursor: pointer
	user-select: none
	transition: text-shadow 0.3s ease
	margin-bottom: 20px
	&:hover
		text-shadow: 0 0 1px $button-color
	&.--invalid
		color: $red
		& > .custom-check
			border-color: $red
.custom-check
	position: absolute
	box-sizing: border-box
	top: 0
	left: 0
	display: block
	width: 20px
	height: 20px
	border: 1px solid $font-dgrey
	border-radius: 5px
	&::after
		content: ''
		position: absolute
		display: block
		width: 12px
		height: 12px
		margin: 3px
		border-radius: 3px
		background-color: $button-color
		transform: scale(5)
		opacity: 0
		transition: all 0.3s
	&::before
		content: ''
		box-sizing: border-box
		position: absolute
		top: -2px
		left: -2px
		display: block
		width: 22px
		height: 22px
		border-radius: 5px
		border: 3px solid $button-color
		transform: scale(5)
		opacity: 0
		transition: all 0.3s
.checkbox-3
	display: none
	&:checked + .label-3
		text-shadow: 0 0 1px $button-color
		& > .custom-check::after,
		& > .custom-check::before
			transform: scale(1)
			opacity: 1
</style>