<template lang="pug">
	.common-comp.cases-comp
		.common-container
			h3.h3.cases-comp__h3._onscroll Кейсы
			Carousel( :perPageCustom="[[320, 1], [630, 2], [1000, 3],]" :navigationEnabled="true" :paginationEnabled="true" )._onscroll
				Slide( v-for="item in SliderItems" ).slide
					article.item
						button(@click="Emit(item.id)").img-link
							img( :src="require(`../assets/images/${item.img}`)" alt="Картинка кейса").img
							.glass
						.content
							h4.h4.h4 {{ item.name }}
							button( @click="Emit(item.id)" ).common-btn.btn Подробнее
</template>

// =========================================================================

<script>
import { Carousel, Slide } from 'vue-carousel'


export default {
	name: 'Cases',
	components: { Carousel, Slide },
	data() {
		return {
			SliderItems: [
				{
					id: 1,
					name: 'Интеграция системы ERP “Галактика” с 1С УПП и 1С ERP через шину данных RabbitMQ',
					img: 'case_1.png',
					link: '#'
				},
				{
					id: 2,
					name: 'Создание личного кабинета МУП ВладимирВодоканал',
					img: 'case_2.png',
					link: '#'
				},
				{
					id: 3,
					name: 'Редизайн и перенос сайта askonamed.ru на 1С-Битрикс',
					img: 'case_3.png',
					link: '#'
				},
				{
					id: 4,
					name: 'Создание сайта Интернет магазина товаров для сна hilding-anders.ru',
					img: 'case_4.png',
					link: '#'
				},
			],
		}
	},
	methods: {
		Emit(id) {
			this.$emit('modal-call', id)
		}
	}
}
</script>

// =========================================================================

<style scoped lang="sass">
//VARIABLES
@import './../assets/styles/__variables.sass'

.cases-comp
	&__h3
		margin-bottom: 35px
.slide
	display: flex
	justify-content: center
	align-items: center
.item
	position: relative
	max-width: 400px
	overflow: hidden
	background-color: #ffffff
	border-radius: 10px
	box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.15)
.img-link
	position: relative
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	height: 300px
	overflow: hidden
	background-color: #fff
	&:hover > .img
		transform: scale(1.1)
	&:hover > .glass
		opacity: 0
.img
	display: block
	width: 100%
	height: auto
	transform: scale(1)
	transition: transform 0.2s
.glass
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-color: #000000
	opacity: 0.3
	transition: opacity 0.2s
.content
	padding: 30px 40px 40px 40px
	height: 275px
.h4
	line-height: 33px
	margin-bottom: 25px
.btn
	color: #ffffff
	position: absolute
	bottom: 40px


@media (min-width: $min_4) and (max-width: $max_4)
	.cases-comp
		&__h3
	.cases-items
		&__item
		&__img-link
		&__img
		&__h4
		&__btn


@media (min-width: $min_3) and (max-width: $max_3)
	.cases-comp
		&__h3

@media (min-width: $min_2) and (max-width: $max_2)
	.cases-comp
		&__h3
			margin-bottom: 10px

@media (max-width: $max_1)
	.cases-comp
		&__h3
			margin-bottom: 50px
</style>