<template lang="pug">
	.common-comp.trust-comp
		.common-container
			h3.h3.trust-comp__h3._onscroll Нам доверяют
			Carousel._onscroll( :perPageCustom="[[320, 1], [500, 2], [700, 3], [1100, 4], [1300, 5]]" :navigationEnabled="true" :paginationEnabled="true" )
				Slide.slide( v-for="item in SliderItems" )
					.item
						img.img( alt="Нам доверяет" :src="require(`./../assets/images/${item.img}`)" )
</template>

// =========================================================================

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
	name: 'Trust',
	components: { Carousel, Slide },
	data() {
		return {
			SliderItems: [
				{
					id: 1,
					img: 'trust_1.png',
				},
				{
					id: 2,
					img: 'trust_2.jpg',
				},
				{
					id: 3,
					img: 'trust_3.png',
				},
				{
					id: 4,
					img: 'trust_4.png',
				},
				{
					id: 5,
					img: 'trust_5.png',
				},
				{
					id: 6,
					img: 'trust_6.png',
				},
				{
					id: 7,
					img: 'trust_7.png',
				},
				{
					id: 8,
					img: 'trust_8.png',
				},
				{
					id: 9,
					img: 'trust_9.png',
				},
				{
					id: 10,
					img: 'trust_10.png',
				},
				{
					id: 11,
					img: 'trust_11.png',
				},
				{
					id: 12,
					img: 'trust_12.png',
				},
			],
		}
	},
}
</script>

// =========================================================================

<style scoped lang="sass">
//VARIABLES
@import './../assets/styles/__variables.sass'
.trust-comp
	&__h3
		margin-bottom: 30px
		@media (max-width: $max_1)
			margin-bottom: 50px
.trust-items
	padding: 50px 0

.item
	width: 200px
	height: 200px
	margin: 0 auto
	box-shadow: 0 0 19px 5px rgba(0, 0, 0, 0.09)
	border-radius: 10px
	overflow: hidden
	display: flex
	justify-content: center
	align-items: center
.img
	width: 90%
	height: auto


@media (min-width: $min_5)
	.trust-comp
		&__h3
	.trust-items
		&__item-frame
		&__item
		&__img
@media (min-width: $min_4) and (max-width: $max_4)
	.trust-comp
		&__h3
	.trust-items
		&__item-frame
		&__item
		&__img
@media (min-width: $min_3) and (max-width: $max_3)
	.trust-comp
		&__h3
	.trust-items
		&__item
		&__img

@media (min-width: $min_2) and (max-width: $max_2)
	.trust-comp
		&__h3
	.trust-items
		&__item
		&__img

@media (min-width: 450px) and (max-width: $max_1)
	.trust-comp
		&__h3
	.trust-items
		&__item
		&__img

@media (max-width: 449px)
	.trust-comp
		&__h3
	.trust-items
		&__item
		&__img

</style>