<template lang="pug">
	transition(name="modal-appear")
		.common-comp.modal-comp(
			v-if="isModalOpened"
		)
			.modal-comp__tapfield(
				@click="$emit('closeModal')"
			)
			.modal-comp__window
				.modal-comp__close(
					@click="$emit('closeModal')"
				) X
				.content
					.img-container
						img(:src="require(`./../assets/images/case_${caseSelected}.png`)" alt="Увеличенное изображение").modal-comp__img
					.text-container
						h4.content__name {{ CasesData[caseSelected-1].name }}
						.content__section( v-for="sect in CasesData[caseSelected-1].sections" :key="sect.id" )
							h5.content__subname {{ sect.subname }}
							.content__text( v-html="sect.text" )
</template>

// =========================================================================

<script>
export default {
	name: 'Modal',
	props: {
		isModalOpened: Boolean,
		caseSelected: Number,
	},
	data() {
		return {
			CasesData: [
				{
					id: 1,
					name: 'Интеграция системы ERP “Галактика” с 1С УПП и 1С ERP через шину данных RabbitMQ',
					sections: [
						{
							subname: 'Цель проекта:',
							text: 'Для ускорения взаимодействия между логистическими аутсорсинговыми и производственными компаниями в реальном времени.',
						},
						{
							subname: 'Что было сделано:',
							text: '<p>Логистическая компания осуществляет свою деятельность на базе ERP «Галактика». Производственные компании осуществляют свою деятельность на базе систем 1С УПП и 1С ERP. </p>В целях оптимизации затрат производственных компаний был заключен договор с логистическим оператором на оказание комплексных услуг, а именно агрегация заказов на производство, размещение заказов на производство, планирование отгрузки, предоставления складских площадей, доставка клиенту. <p></p><p>Для реализации данного договора была создана схема обмена между двумя разнородными информационными системами компаний используя шину данных RabbitMQ.</p>',
						},
						{
							subname: 'Что получили:',
							text: '<ul><li>Снизили количество ошибок операторов при проведении документооборота между вовлеченными в проект компаниями</li><li>Позволили контролировать в реальном времени заказы, остатки, резервы и отгрузки производственным компаниям без затрат на собственных специалистов</li></ul>',
						},
					]
				},
				{
					id: 1,
					name: 'Создание личного кабинета МУП ВладимирВодоканал',
					sections: [
						{
							subname: 'Цель проекта:',
							text: 'Создать личный кабинет для клиентов МУП Владимирводоканал, в котором они могли бы контролировать свои показания счетчиков, проводить оплаты, взаимодействовать с организацией. Для управления сайтом необходимо использовать существующую базу данных на базе 1С Водоканал.',
						},
						{
							subname: 'Что было сделано:',
							text: '<ul><li>Создан веб сайт для клиентов, где было возможно проводить оплаты, контролировать показания счетчиков, направлять обратную связь в компанию</li><li>Создан модуль для 1С Водоканал для управления данным сайтом</li><li>Созданы интеграционные модули для передачи информации между сайтом и базой 1С в режиме онлайн.</li></ul>',
						},
						{
							subname: 'Что получили:',
							text: 'В результате проекта получили полнофункциональный личный кабинет клиента, функционирующий в режиме онлайн с базой данных 1С Заказчика. Технологическими решениями добились того, что в личном кабинете смогли одновременно работать более 10000 одновременных клиентов, при этом нагрузка на базу данных 1С была минимальна и не потребовала улучшения оборудования, на котором она функционировала.',
						},
					]
				},
				{
					id: 1,
					name: 'Редизайн и перенос сайта askonamed.ru на 1С-Битрикс',
					sections: [
						{
							subname: 'Цель проекта:',
							text: 'Обновить визуальную составляющую сайта и перенести его на более современную CMS 1С-Битрикс:Управление сайтом.',
						},
						{
							subname: 'Что было сделано:',
							text: '<ul><li>Полностью переделали визуальную составляющую сайта, улучшили восприятие информации UI/UX</li><li>Перенесли сайт на новую CMS 1С-Битрикс, упростили работу контент менеджера, стабилизировали работу сайта</li></ul>',
						},
					]
				},
				{
					id: 1,
					name: 'Создание сайта Интернет магазина товаров для сна hilding-anders.ru',
					sections: [
						{
							subname: 'Цель проекта:',
							text: 'Создание с "нуля" полноценного высоконагруженного Интернет магазина для компании, более 20 000 SKU.',
						},
						{
							subname: 'Что было сделано:',
							text: '<ul><li>Реализована визуальная составляющая сайта, проработан UI/UX дизайн</li><li>Разработан сайт на CMS 1С-Битрикс</li><li>Настроены интеграции с CRM клиента, информационной системой клиента</li></ul>',
						},
					]
				},
			]
		}
	}
}
</script>

// =========================================================================

<style scoped lang="sass">
//VARIABLES
@import './../assets/styles/__variables.sass'

.modal-comp
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	z-index: 200
	display: flex
	justify-content: center
	align-items: center
	&__tapfield
		position: absolute
		top: 0
		left: 0
		width: 100vw
		height: 100vh
		background: #000
		opacity: 0.5
		cursor: pointer
	&__window
		position: absolute
		background: #fff
		width: 100vw
		max-width: 90vw
		height: auto
		max-height: 90vh
		overflow-y: auto
		border-radius: 10px
		padding: 10px 40px 50px 20px
	&__img
		width: 300px
		height: auto
		padding: 40px 20px 20px 20px
		@media (max-width: $max_2)
			width: 200px
	&__close
		position: absolute
		top: 10px
		right: 15px
		font-size: 28px
		cursor: pointer
		color: $button-color

.content
	display: flex
	justify-content: flex-start
	align-items: flex-start
	// @media (min-width: $min_2) and (max-width: $max_2)
	@media (max-width: $max_1)
		display: block
	&__name
		font-size: 30px
		line-height: 30px
		font-weight: 700
		margin-bottom: 20px
		@media (max-width: $max_1)
			margin-top: 0
	&__subname
		font-size: 24px
		margin-bottom: 10px
	&__text
		font-size: 16px

.modal-appear
	&-enter,
	&-leave-to
		opacity: 0
	&-enter-active,
	&-leave-active
		transition: opacity 0.5s ease
	&-enter-to,
	&-leave
		opacity: 1

</style>