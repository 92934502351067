<template lang="pug">
	.common-comp.header-comp
		.common-container
			transition(name="burger-appear")
				.menu-burger(
					v-if="isBurgerOpened"
				)
					nav.menu-burger__nav
						ul.menu-burger__ul
							li.menu-burger__li(
								v-for="(val,key) in headerData.navArr"
							)
								a.common-anchor.menu-burger__nav-link(
									:href="val" 
									@click="$emit('isBurgerOpened')"
								) {{ key }}
							a.common-anchor.menu-burger__tel(
								:href="headerData.tel[1]"
								@click="$emit('isBurgerOpened')"
							) {{ headerData.tel[0] }}
							button.common-btn.menu-burger__contactus(
								@click="$emit('isBurgerOpened'); $emit('openform', 'short-form')"
							) Написать нам

			.topline
				button.topline__open-burger(
					@click="$emit('isBurgerOpened')"
				)
					.topline__btn-stripe(
						:class="isBurgerOpened? 'topline__btn-stripe--opened' : ''"
					)
				h1.h1.topline__h1
					a(href="#").topline__logo-link
						img(src="./../assets/images/topline__logo.png" alt="Логотип 42Solution").topline__logo
				nav.topline__nav
					ul.topline__ul
						li.topline__li.abitsm-txt(
							v-for="(val,key) in headerData.navArr"
						)
							a(:href="val").common-anchor.topline__nav-link {{ key }}
				a(:href="headerData.tel[1]").common-anchor.topline__tel {{ headerData.tel[0] }}
				a(:href="headerData.tel[1]").topline__tel-ico
				button.common-btn.topline__contactus(
					@click="$emit('openform', 'short-form')"
				) Написать нам
</template>

// =========================================================================

<script>
export default {
	name: 'Header',
	props: {
		isBurgerOpened: Boolean
	},
	data() {
		return {
			headerData: {
				navArr: {
					"Кейсы": "#cases",
					"Нам доверяют": "#trust",
					"Контакты": "#footer",
				},
				tel: [
					"8 800 600-64-24",
					"tel:88006006424",
				]
			},
		}
	}
}
</script>

// =========================================================================

<style scoped lang="sass">
//VARIABLES
@import './../assets/styles/__variables.sass'

.header-comp

.menu-burger
	position: fixed
	z-index: 10
	top: 0
	left: 0
	display: none
	width: 100vw
	height: 100vh
	background-color: $bg-white
	margin-top: 128px
	padding-bottom: 30px
	&__nav
		width: 100%
		height: 70%
	&__ul
		width: 95%
		margin: 0 auto
		height: 100%
		max-height: 400px
		display: flex
		flex-direction: column
		flex-wrap: nowrap
		justify-content: space-between
		align-items: center
	&__li
	&__nav-link
		color: $font-dgrey
	&__tel
		color: $font-dgrey
	&__contactus


.topline
	position: relative
	z-index: 20
	display: flex
	flex-direction: row
	flex-wrap: no-wrap
	justify-content: space-between
	align-items: center
	padding: 20px 0
	&__open-burger
		position: relative
		left: -10px
		display: none
		justify-content: center
		align-items: center
		width: 40px
		height: 40px
		background-color: #fff
		transition: transform 0.1s
		&:hover
			transform: scale(1.2)
	&__btn-stripe
		position: relative
		width: 20px
		height: 4px
		background-color: #000
		transition: background-color 0.2s
		&:before,
		&:after
			content: ''
			position: absolute
			left: 0
			width: 100%
			height: 4px
			background-color: #000
			transition: all 0.2s ease
		&:before
			top: -6px
		&:after
			bottom: -6px 
		&--opened
			background-color: transparent
			&:before
				width: 29px
				transform: rotate(45deg)
				top: 0px
				left: -4px
			&:after
				width: 29px
				transform: rotate(-45deg)
				bottom: 0px
				left: -4px
	&__h1
		margin: 0
	&__logo-link
		display: block
	&__logo
		width: 200px
		height: auto
	&__nav
		height: 50px
	&__ul
		display: flex
		flex-direction: row
		flex-wrap: no-wrap
		justify-content: space-between
		align-items: center
		width: 100%
		height: 100%
	&__li
		margin: 10px 20px
	&__nav-link
		display: block
		width: 100%
		height: 100%
		text-align: center
	&__tel
		font-size: 21px
		font-weight: 700
		line-height: 30px
	&__tel-ico
		position: relative
		right: -10px
		display: none
		width: 40px
		height: 40px
		background-image: url('./../assets/images/ico_call.svg')
		background-size: 50%
		background-position: center
		background-repeat: no-repeat
		transition: transform 0.1s
		&:hover
			transform: scale(1.2)
	&__contactus

.burger-appear
	&-enter,
	&-leave-to
		opacity: 0
	&-enter-active,
	&-leave-active
		transition: opacity 0.5s ease
	&-enter-to,
	&-leave
		opacity: 1



// MEDIA QUERIES
@media (min-width: $min_4) and (max-width: $max_4)
	.header-comp
	.menu-burger
		&__nav
		&__ul
		&__li
		&__nav-link
		&__tel
		&__contactus
	.topline
		&__open-burger
		&__h1
		&__logo-link
		&__logo
		&__nav
		&__ul
		&__li
		&__nav-link
		&__tel
		&__tel-ico
		&__contactus
@media (min-width: $min_3) and (max-width: $max_3)
	.header-comp
	.menu-burger
		&__nav
		&__ul
		&__li
		&__nav-link
		&__tel
		&__contactus
	.topline
		&__open-burger
		&__h1
		&__logo-link
		&__logo
		&__nav
		&__ul
		&__li
		&__nav-link
		&__tel
			display: none
		&__tel-ico
			display: block
		&__contactus
@media (min-width: $min_2) and (max-width: $max_2)
	.header-comp
	.menu-burger
		display: block
		&__nav
		&__ul
		&__li
		&__nav-link
		&__tel
		&__contactus
	.topline
		&__open-burger
			display: flex
		&__h1
		&__logo-link
		&__logo
		&__nav
			display: none
		&__ul
		&__li
		&__nav-link
		&__tel
			display: none
		&__tel-ico
			display: block
		&__contactus
			display: none
@media (max-width: $max_1)
	.header-comp
	.menu-burger
		display: block
		margin-top: 96px
		&__nav
		&__ul
		&__li
		&__nav-link
		&__tel
		&__contactus
	.topline
		&__open-burger
			display: flex
		&__h1
		&__logo-link
		&__logo
			width: 120px
			height: auto
		&__nav
			display: none
		&__ul
		&__li
		&__nav-link
		&__tel
			display: none
		&__tel-ico
			display: block
		&__contactus
			display: none
</style>