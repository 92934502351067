<template lang="pug">

	.common-comp.stages-comp
		.common-container
			h3.h3.h3-big-bottom.stages-comp__h3._onscroll Этапы работы
			Carousel._onscroll( v-model="value" :perPageCustom="[[320, 1]]" :navigationEnabled="true" :paginationEnabled="true" )
				Slide.slide( v-for="stage in Stages" :key="stage.id" )
					article.stage
						img(:src="require(`./../assets/images/stages__stage_${stage.img}.svg`)" alt="Этапы работы - иллюстрация").stage__logo
						h4.h4.stage__name {{ stage.name }}
						ul.stage__ul
							li(
								v-for="li in stage.lis"
							).stage__li {{ li }}
			.stages-numbers(
				:class="'stages-numbers--' + value"
			)
				.stages-numbers__line
				div(
					v-for="(stage, index) in Stages"
				).stages-numbers__item
					div(
						:class="index === value? 'stages-numbers__num--active' : ''"
						@click="value = index"
					).stages-numbers__num {{ index }}

</template>

// =========================================================================

<script>
import { Carousel, Slide } from 'vue-carousel'

export default {
	name: 'Stages',
	components: { Carousel, Slide },
	data() {
		return {
			value: 0,
			Stages: [
				{
					id: 1,
					img: '1',
					name: '1. Подготовка',
					lis: [
						'Менеджер проекта принимает заявку, уточняет ваши задачи и потребности. При необходимости, пишет и согласовывает техническое задание.',
						'Подписываем соглашение о неразглашении.',
						'Начинаем работу.',
					],
				},
				{
					id: 2,
					img: '2',
					name: '2. Разработка, тестирование',
					lis: [
						'Разработчик или группа разработчиков программирует и тестирует решение. Передает на тестирование Менеджеру проекта.',
						'Менеджер проекта тестирует решение. Если возникают проблемы - возвращает задачу программисту на доработку. После окончательной проверки - передает на тестирование Заказчику.',
					],
				},
				{
					id: 3,
					img: '3',
					name: '3. Внедрение',
					lis: [
						'Переносим разработку на вашу рабочую среду. Закрываем задачу.',
					],
				},
				{
					id: 4,
					img: '4',
					name: '4. Расчет',
					lis: [
						'Ежемесячно формируем отчет о трудозатратах. Формируем акт.',
						'Выставляем счет. Вы оплачиваете работу.',
					],
				},
			],
		}
	}
}
</script>

// =========================================================================

<style scoped lang="sass">
//VARIABLES
@import './../assets/styles/__variables.sass'

.stages-comp
	&__h3
		// margin-bottom: 57px
	&-frame
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		justify-content: flex-start
		align-items: center
		height: 150px
		overflow: visible
.stage
	position: relative
	display: grid
	grid-template-columns: 230px auto
	grid-column-gap: 60px
	padding: 50px
	margin-bottom: 15px
	border-radius: 30px
	background-color: #fff
	box-shadow: 0px 25px 31px rgba(0, 0, 0, 0.05)
	height: 96%
	&::after
		content: ''
		position: absolute
		left: 50%
		transform: translate(-29px, 0)
		bottom: -29px
		display: block
		width: 58px
		height: 29px
		background-image: url('./../assets/images/stage-ref.png')
		border-radius: -10px 
	&__logo
		position: relative
		grid-column: 1
		grid-row-start: 1
		grid-row-end: 3
		min-width: 230px
		width: 230px
		height: 230px
		background-repeat: no-repeat
		background-position: center
	&__ul
	&__li
		margin-bottom: 30px
		list-style-type: disc
		color: $font-dgrey
		margin-left: 15px
	&__name
		margin-bottom: 30px
	&__ref
.stages-numbers
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: center
	margin-left: 50%
	transition: transform 0.2s ease
	&--0
		transform: translate(-0px, 0px)
	&--1
		transform: translate(-150px, 0px)
	&--2
		transform: translate(-300px, 0px)
	&--3
		transform: translate(-450px, 0px)
	&__line
		position: absolute
		width: 450px
		@media (max-width: $max_1)
			width: 300px
		height: 0
		border-bottom: 1px dashed $font-dgrey
	&__item
		min-width: 150px
		width: 150px
		transform: translate(-75px, 0)
	&__num
		position: relative
		min-width: 87px
		width: 87px
		height: 87px
		margin: 0 auto
		background-color: #fff
		box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.05)
		border-radius: 50%
		color: #000
		font-weight: 900
		font-size: 24px
		line-height: 87px
		text-align: center
		transition: transform 0.3s ease
		user-select: none
		cursor: pointer
		transition: background-color 0.2s
		&:hover
			background-color: $bg-grey
		&--active
			color: #EB5757
			transform: scale(1.3)


// @media (min-width: $min_4) and (max-width: $max_4)
// @media (min-width: $min_3) and (max-width: $max_3)
// @media (min-width: $min_2) and (max-width: $max_2)
@media (max-width: $max_1)
	.stages-comp
		&__h3
			margin-bottom: 45px
		&-frame
	.stage
		padding: 20px
		padding-bottom: 40px
		grid-template-columns: 90px auto
		grid-column-gap: 20px
		&::after
		&__logo
			min-width: 90px
			width: 90px
			height: 90px
			grid-column: 1
			grid-row: 1
			background-size: 40%
		&__circle
			min-width: 90px
			width: 90px
			height: 90px
			&_1
				background-size: 70%
			&_2
				background-size: 85%
			&_3
				background-size: 100%
		&__ul
			grid-column-start: 1
			grid-column-end: 3
			grid-row: 2
			margin: 0
		&__li
			margin: 20px 0 0 20px
		&__name
			grid-column: 2
			grid-row: 1
			align-self: center
			margin: 0
		&__ref
	.stages-numbers
		padding-bottom: 30px
		&--0
			transform: translate(-0px, 0px)
		&--1
			transform: translate(-100px, 0px)
		&--2
			transform: translate(-200px, 0px)
		&--3
			transform: translate(-300px, 0px)
		&__line
		&__item
			min-width: 100px
			width: 100px
			transform: translate(-50px, 0)
		&__num
			min-width: 57px
			width: 57px
			height: 57px
			line-height: 57px
			&--active
</style>