<template lang="pug">
	.common-comp
	
</template>

// =========================================================================

<script>
export default {
	name: 'Intro',
}
</script>

// =========================================================================

<style scoped lang="sass">
//VARIABLES
@import './../assets/styles/__variables.sass'
</style>