<template lang="pug">
	#app.app( :class="(isOpened || isBurgerOpened)? 'app--nonsroll' : ''" )
		.intro
			Intro
		header#header.common-header.header._onscroll
			Header(
				@openform="FormOpen"
				@isBurgerOpened="isBurgerOpened = !isBurgerOpened"
				:isBurgerOpened="isBurgerOpened"
			)
		header#header-sticky.common-header.header.header-sticky
			Header(
				@openform="FormOpen"
				@isBurgerOpened="isBurgerOpened = !isBurgerOpened"
				:isBurgerOpened="isBurgerOpened"
				:class=" isBurgerOpened? 'header-sticky--visible-imp' : '' "
			)
		main#main.common-main.main
			.main__context
				.rects
					.tri.tri_main
					.rect.rect_main
				Main( @openform="FormOpen" )
			section#advs.common-section.advs
				Advs
			section#cases.common-section.cases
				.rects
					.tri.tri_cases
					.rect.rect_cases
				Cases( @modal-call="callModal" )
			//- section#servs.common-section.servs
			//- 	.rects
			//- 		.tri.tri_servs
			//- 		.rect.rect_servs
			//- 	Servs
			//- section#about.common-section.about
			//- 	About
			section#stages.common-section.stages
				.rects
					.tri.tri_stages
					.rect.rect_stages
				Stages
			//- section#whyus.common-section.whyus
			//- 	Whyus
			//- section#command.common-section.command
			//- 	.rects
			//- 		.tri.tri_command
			//- 		.rect.rect_command
			//- 	Command
			//- section#sertifs.common-section.sertifs
			//- 	Sertifs( @callModal="callModal" )
			section#trust.common-section.trust
				Trust
			//- section#feedback.common-section.feedback
			//- 	.rects
			//- 		.tri.tri_feedback
			//- 		.rect.rect_feedback
			//- 	Feedback( @callModal="callModal" )
		footer#footer.common-header.footer
			Footer(@openform="FormOpen")
		.form
			Form( :which='which' :isOpened="isOpened" @formclose="FormClose" @submiting="submiting" :isSubmitted="isSubmitted" :FormPermission="FormPermission")
		.modal
			Modal(
				:isModalOpened="isModalOpened"
				:caseSelected="caseSelected"
				@closeModal="isModalOpened = false"
			)
		FollowUs
</template>

// =========================================================================

<script>
import Intro		from './components/Intro.vue'
import Header		from './components/Header.vue'
import Main		from './components/Main.vue'
import Footer		from './components/Footer.vue'
import Advs		from './components/Advs.vue'
import Cases		from './components/Cases.vue'
// import Servs		from './components/Servs.vue'
// import About		from './components/About.vue'
import Stages		from './components/Stages.vue'
// import Whyus		from './components/Whyus.vue'
// import Command		from './components/Command.vue'
// import Sertifs		from './components/Sertifs.vue'
import Trust		from './components/Trust.vue'
// import Feedback	from './components/Feedback.vue'
import Form		from './components/Form.vue'
import Modal		from './components/Modal.vue'
import FollowUs from './components/FollowUs'


export default {
	name: 'App',
	components: {
		Intro,
		Header,
		Main,
		Footer,
		Advs,
		Cases,
		// Servs,
		// About,
		Stages,
		// Whyus,
		// Command,
		// Sertifs,
		Trust,
		// Feedback,
		Form,
		Modal,
		FollowUs
	},
	data() {
		return {
			isBurgerOpened: false,
			which: 'short-form',
			isOpened: false,
			isModalOpened: false,
			imgForModal: '',
			caseSelected: 1,
			FormPermission: true,
			ThanksTimeout: undefined,
			isSubmitted: false,
		}
	},
	methods: {
		submiting() {
			this.FormPermission = false
			this.isSubmitted = true
			this.ThanksTimeout = setTimeout(() => {this.isSubmitted = false}, 2000)
		},
		FormOpen(param) {
			if(this.FormPermission) {
				document.getElementById('app').style.overflowY = 'hidden'
				if(param) this.which = param
				if(this.which === 'short-form') {
					eval("ym(83168674,'reachGoal','click_write_to_us')")
				} else {
					eval("ym(83168674,'reachGoal','click_evaluation_project')")
				}
				this.isOpened = true
			} else {
				this.isSubmitted = true
				this.ThanksTimeout = setTimeout(() => {this.isSubmitted = false}, 2000)
			}
		},
		FormClose() {
			document.getElementById('app').style.overflowY = 'auto'
			this.isOpened = false
		},
		callModal(id) {
			this.caseSelected = id
			this.isModalOpened = true
		},
		scrollDoc(e) {
			// header
			let appScroll = document.getElementById('app').scrollTop
			if (appScroll > 400 && e.deltaY < 0) {
				document.getElementById('header-sticky').classList.add('header-sticky--visible')
			} else if (!this.isBurgerOpened) {
				document.getElementById('header-sticky').classList.remove('header-sticky--visible')
			}
		},
	},
	mounted: function() {
		document.addEventListener('wheel', this.scrollDoc)

		let totalScroll = document.getElementById('app').scrollTop
		let appearances = document.querySelectorAll('._onscroll')
		appearances.forEach((item) => {
			if(item.getBoundingClientRect().y < window.innerHeight * 0.8) {
				item.classList.add('_onscroll--activated')
				item.classList.remove('_onscroll')
			}
		})

		// all elements appearance
		
		setInterval(() => {
			if(document.getElementById('app').scrollTop !== totalScroll) {
				totalScroll = document.getElementById('app').scrollTop
				appearances.forEach((item) => {
					if(item.getBoundingClientRect().y < window.innerHeight * 0.9) {
						item.classList.add('_onscroll--activated')
						item.classList.remove('_onscroll')
					}
				})
			}
		},100)
	}
}
</script>

// =========================================================================

<style lang="sass">
//VARIABLES
@import './assets/styles/__variables.sass'
//NORMALIZE
@import './assets/styles/reset.sass'
//COMMON
@import './assets/styles/common.sass'
//RECTS
@import './assets/styles/rects.sass'
//TYPOGRAPH
@import './assets/styles/typograph.sass'
//SCROLL-ANIM
@import './assets/styles/scroll-anim.sass'
//SLIDERS
@import './assets/styles/__sliders.sass'
//FORM
// @import './assets/styles/__b24-form.sass'



html
	width: 100vw
	height: 100vh
	overflow: hidden
	::selection
		background: $button-color
		color: $font-white
#app
	width: 100%
	height: 100vh
	scroll-behavior: smooth
	overflow-x: hidden
	&--nonscroll
		overflow: hidden
	&::-webkit-scrollbar
		width: 10px
	&::-webkit-scrollbar-thumb
		border-radius: 10px
		background-color: $button-color
	&::-webkit-scrollbar-track
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2)
		border-radius: 10px
		background-color: #f9f9fd
.h2 
	margin: 0
.h3
	margin: 0
	margin-bottom: 60px
	max-width: 85%
.h4
	margin: 0


// @media (min-width: $min_4) and (max-width: $max_4)
// @media (min-width: $min_3) and (max-width: $max_3)
// @media (min-width: $min_2) and (max-width: $max_2)
@media (max-width: $max_1)
	.h3
		margin-bottom: 20px


//APP STYLES
.app
.intro
.header
.main
	&__context
.main__context
	position: relative
	z-index: 5
.advs
	position: relative
	z-index: 10
	@media (max-width: $max_1)
		padding-bottom: 30px
.footer
.form
	position: fixed
	z-index: 100
.header
	position: relative
	z-index: 20
.header-sticky
	width: 100vw
	position: fixed
	z-index: 30
	top: -128px
	transition: top 0.5s
	&--visible
		top: 0
		&-imp
			top: 0 !important


</style>
