<template lang="pug">
	.form-modal
		transition(name='tapfield-appear')
			.tapfield(v-show='isOpened' @click='FormClose()')
		transition(name='modal-appear')
			div(v-show='isOpened')
				FormProj(v-show='which === "full-form"' :formId='0' :which='which' @formclose='FormClose' @submiting='Submiting' :FormPermission="FormPermission")
				FormAsk(v-show='which === "short-form"' :formId='1' :which='which' @formclose='FormClose' @submiting='Submiting' :FormPermission="FormPermission")
		transition(name='tapfield-thanks-appear')
			.tapfield(v-show='isSubmitted')
		transition(name='thanks-appear')
			p.thanks(v-show='isSubmitted') 
				span.thanks__h Спасибо!
				span.thanks__desc Мы записали
</template>

<script>
import FormProj from './form-parts/FormProj'
import FormAsk from './form-parts/FormAsk'

export default {
	name: 'Form',
	components: {
		FormProj,
		FormAsk,
	},
	props: {
		isOpened: Boolean,
		which: {
			type: String,
			default: 'short-form'
		},
		isSubmitted: Boolean,
		FormPermission: Boolean,
	},
	methods: {
		FormClose() {
			this.$emit("formclose")
		},
		Submiting() {
			this.$emit("submiting")
			this.FormClose()
			console.log('SUBMITTED')
		}
	},
}
</script>

<style lang="sass" scoped>
@import './../assets/styles/__variables.sass'

.form-modal
	position: fixed
	top: 0
	left: 0
	&__h3
		color: $bg-white
		margin: 0
		max-width: 90%
		font-size: 32px
		position: absolute
		bottom: 30px
		right: 30px
		text-align: right
.tapfield
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	background: linear-gradient(to bottom, rgba($button-color, 1) 0%, rgba($button-color, 0.2) 100%)
	cursor: pointer
.thanks
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	&__h
		display: block
		font-size: 48px
		color: $font-white
	&__desc
		display: block
		color: $font-white
		font-size: 28px


.tapfield-appear
	&-enter,
	&-leave-to
		opacity: 0
	&-enter-active,
	&-leave-active
		transition: opacity 0.5s ease
	&-enter-to,
	&-leave
		opacity: 1

.modal-appear
	&-enter
		opacity: 0
		transform: translate(0, 200px)
	&-leave-to
		opacity: 0
		transform: translate(0, -200px)
	&-enter-active,
	&-leave-active
		transition: opacity 0.5s ease, transform 0.5s ease
	&-enter-to,
	&-leave
		opacity: 1
		transform: translate(0, 0px)

.tapfield-thanks-appear
	&-enter,
	&-leave-to
		opacity: 0
	&-enter-active
		transition: opacity 0.5s ease 0.3s
	&-leave-active
		transition: opacity 0.5s ease
	&-enter-to,
	&-leave
		opacity: 1

.thanks-appear
	&-enter
		opacity: 0
		transform: translate(0, 200px)
	&-leave-to
		opacity: 0
		transform: translate(0, -200px)
	&-enter-active
		transition: opacity 0.5s ease 0.3s, transform 0.5s ease 0.3s
	&-leave-active
		transition: opacity 0.5s ease, transform 0.5s ease
	&-enter-to,
	&-leave
		opacity: 1
		transform: translate(0, 0px)
</style>