<template lang="pug">
	transition(name='popup-appear')
		.popup(v-if='isActive')
			.popup__tapfield(@click='closePopup()')
			.popup__window
				button.exit(@click.prevent='closePopup()')
					font +
				.popup__content
					h3.popup__title Подпишитесь на наш Телеграм канал - 42Solution - о 1С жизни, вселенной разработки и всяком таком
					div
						p.popup__p Рассказываем про реальные кейсы, тренды создания продающих сайтов с пользой для клиента, о работе нашей распределенной команды и философии айкидо для бизнеса. Без рекламы и спама.
						a.follow(href='https://t.me/tech_1C_web', target='_blank') Подписаться
</template>

<script>
export default {
	name: "FollowUs",
	data() {
		return {
			isActive: false,
		};
	},
	mounted: function() {
		this.$nextTick(function() {
			if (!this.$cookies.get('42followus')) {
				this.$cookies.set('42followus', true, '1m');
				this.isActive = true;
				document.querySelector('html').style.overflowY = 'hidden';
			}
		});
	},
	methods: {
		closePopup() {
			this.isActive = false;
			document.querySelector('html').style.overflowY = 'auto';
		},
	},
};
</script>

<style lang="sass" scoped>
@import './../assets/styles/__variables.sass'

.popup
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	z-index: 200
	display: flex
	justify-content: center
	align-items: center

	&__tapfield
		position: absolute
		top: 0
		left: 0
		width: 100vw
		height: 100vh
		background: linear-gradient(to bottom, #00b6f0 0%, rgba(0, 182, 240, 0.2) 100%)
		cursor: pointer

	&__window
		position: absolute
		width: 95vw
		max-width: 700px
		height: auto
		max-height: 90vh
		overflow: hidden

	&__content
		background-color: #fff
		width: 100%
		max-height: calc(90vh - 68px)
		overflow-y: auto
		padding: 40px 40px 60px
		@media (max-width: $max_1)
			padding: 20px 20px 100px
		&::-webkit-scrollbar
			width: 10px
		&::-webkit-scrollbar-thumb
			border-radius: 10px
			background-color: #00b6f0
		&::-webkit-scrollbar-track
			-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2)
			background-color: #fff

	&__title
		margin: 0
		margin-bottom: 30px
		font-weight: 700
		font-size: 28px
		line-height: 115%
		color: #000

	&__p
		margin: 0
		font-weight: 400
		font-size: 18px
		line-height: 140%
		color: #130D30

.follow
	display: flex
	justify-content: center
	align-items: center
	border-radius: 60px
	padding: 15px 30px
	margin-top: 40px
	font-size: 18px
	transition: 0.3s ease
	transition-property: background-color, color, transform
	background-color: #00b6f0
	border: 1px solid #00b6f0
	font-weight: 400
	color: #fff
	&:hover
		background-color: transparent
		color: #00b6f0
		transform: scale(0.98)

.exit
	margin: 10px
	float: right
	position: relative
	display: flex
	justify-content: center
	align-items: center
	width: 48px
	height: 48px
	padding: 0
	color: #fff
	background-color: transparent
	cursor: pointer
	transition: color 0.3s ease
	& > font
		display: block
		position: relative
		left: 1px
		width: 100%
		height: 100%
		font-size: 48px
		line-height: 48px
		text-align: center
		transform: rotate(45deg)
	&::after
		display: block
		content: ''
		position: absolute
		width: 100%
		height: 100%
		border: 2px solid #fff
		border-radius: 50%
		opacity: 0
		transform: scale(1.3)
		transition: all 0.3s ease
	&:hover
		color: #fff
		&::after
			opacity: 1
			transform: scale(1)

.popup-appear
	&-enter,
	&-leave-to
		opacity: 0
	&-enter-active,
	&-leave-active
		transition: opacity 0.5s ease
	&-enter-to,
	&-leave
		opacity: 1
</style>
