<template lang="pug">
	form.pop-up(action='')
		fieldset.form-modal__section.form-modal__section_0
			h3.h3.form-modal__h3 {{ which === 'full-form' ? 'Анкета для новых клиентов' : 'Задайте свой вопрос' }}
			button.exit(@click.prevent='FormClose()')
				font +

		//- REQUIRED:
		formSmall(:formId='formId' :which='which' @submiting='Submiting' :FormPermission="FormPermission")

		fieldset(v-if='which === "full-form"').form-modal__section.form-modal__section_1
			.legend.legend_1(@click='NeedOptionalForm = !NeedOptionalForm' :class='NeedOptionalForm? "legend_drop" : ""')
				<svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 12L11 1.52381L1 12" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
				| Пожалуйста, заполните форму ниже - нам это поможет
			hr
			.dropdown(:class='NeedOptionalForm ? "dropdown--dropped" : ""')
				label(:for="`input-1-1--${formId}`").label-1 Чем вы занимаетесь?
				textarea(
					v-model='Answers.chemZanim'
					name="chemZanim"
					:id="`input-1-1--${formId}`"
				).textarea-1
				label(:for="`input-1-2--${formId}`").label-1 В чем ваша задача?
				textarea(
					v-model='Answers.vChemZadacha'
					name="vChemZadacha"
					:id="`input-1-2--${formId}`"
				).textarea-1
				label(:for="`input-1-3--${formId}`").label-1 Каким вы видите решение задачи?
				textarea(
					v-model='Answers.reshenie'
					name="reshenie"
					:id="`input-1-3--${formId}`"
				).textarea-1
				label(:for="`input-1-4--${formId}`").label-1 Какие у вас ожидания от результата?
				textarea(
					v-model='Answers.ogidanie'
					name="ogidanie"
					:id="`input-1-4--${formId}`"
				).textarea-1
				label(:for="`input-1-5--${formId}`").label-1 Сколько денег планируете потратить?
				textarea(
					v-model='Answers.money'
					name="money"
					:id="`input-1-5--${formId}`"
				).textarea-1
				label(for="input-file").label-file
					<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16 16C10.64 16 5.36 16 0 16C0 14.32 0 9.68 0 8C1.12 8 2 8 3.2 8V9.6H1.6V14.4H14.4V9.6H12.8V8C13.92 8 14.8 8 16 8C16 9.68 16 14.4 16 16ZM11.92 5.92002L10.88 4.72002L8.8 6.63999V0H7.2V6.72002L5.2 4.8L4.07998 6L8 9.76001L11.92 5.92002Z" fill="white"/></svg>
					| Прикрепить файл
				span.file-line(v-if='file') {{ file.name }}
				input(
					@input='FileInput'
					type="file"
					name="input-file"
					id="input-file"
					accept=".jpg, .jpeg, .png, .gif, .bmp, .doc, .docx, .xls, .xlsx, .txt, .tar, .zip, .7z, .7zip"
					multiple
				).input-file
</template>

<script>
import formSmall from './formSmall'

export default {
	name: 'FormModal',
	components: {
		formSmall,
	},
	props: {
		which: String,
		formId: Number,
		FormPermission: Boolean
	},
	data() {
		return {
			NeedOptionalForm: false,
			Answers: {
				title: '',
				fio: '',
				phone: '',
				mail: '',
				comment: '',
				chemZanim: '',
				vChemZadacha: '',
				reshenie: '',
				ogidanie: '',
				money: '',
				fileName: '',
				fileBase64: '',
			},
			file: ''
		}
	},
	methods: {
		FileInput(e) {
			this.file = e.target.files[0]
		},
		FormClose() {
			this.$emit("formclose")
		},
		Submiting(answers) {
			this.Answers.fio = answers.fio
			this.Answers.phone = answers.phone
			this.Answers.mail = answers.mail
			this.Answers.comment = answers.comment
			this.Answers.title = 'Задать вопрос - 42SOL.RU - Модальная краткая форма'
			function getBase64(file) {
				let reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () => { console.log(reader.result) }
				reader.onerror = (err) => { console.log('Error', err) }
			}
			this.Answers.fileName = this.file.name
			if(this.file) this.Answers.fileBase64 = getBase64(this.file)

			let param = 'FIELDS[TITLE]='+ this.Answers.title +
									'&FIELDS[NAME]=' + this.Answers.fio +
									'&FIELDS[PHONE][0][VALUE]=' + this.Answers.phone + '&FIELDS[PHONE][0][VALUE_TYPE]=WORK' +
									'&FIELDS[EMAIL][0][VALUE]=' + this.Answers.mail + '&FIELDS[EMAIL][0][VALUE_TYPE]=WORK' +
									'&FIELDS[COMMENTS]=' + this.Answers.comment +
									'&FIELDS[UF_CRM_1619698211610]=' + this.Answers.chemZanim +
									'&FIELDS[UF_CRM_1619698278919]=' + this.Answers.vChemZadacha +
									'&FIELDS[UF_CRM_1619698294896]=' + this.Answers.reshenie +
									'&FIELDS[UF_CRM_1619698307616]=' + this.Answers.ogidanie +
									'&FIELDS[UF_CRM_1619698320794]=' + this.Answers.money +
									'&FIELDS[UF_CRM_1619698360471][fileData][0]=' + this.Answers.fileName +
									'&FIELDS[UF_CRM_1619698360471][fileData][1]=' + this.Answers.fileBase64
			function restapiall(method = 'crm.lead.add', param) {
				return new Promise(function(resolve){
					let url = 'https://digitaladvance.bitrix24.ru/rest/18/ahlb6jk1qe6osqg7/'+method+'.json?'+param;
					let xhr = new XMLHttpRequest ();
					xhr.open("GET", url, true);
					xhr.onload = function(result){
						resolve(result);
					}
					xhr.send();
				})
			}
			restapiall('crm.lead.add', param).then(this.$emit("submiting"))
		}
	},
}
</script>

<style lang="sass" scoped>
@import './../../assets/styles/__variables.sass'

.form-modal
	&__h3
		color: $font-white
		margin: 0
		max-width: 90%
		font-size: 32px
		position: absolute
		bottom: 30px
		right: 30px
		text-align: right
	&__section
		width: 100%
		margin: 0
		border: none
		appearance: none
		padding: 40px 24px
		@media (max-width: $max_1)
			padding: 20px 24px
		&_0
			position: relative
			height: 190px
			background-color: $button-color
			background-repeat: no-repeat
		&_1
			background-color: $bg-white
			padding-top: 0
			padding-bottom: 50px
		&_2
			background-color: $bg-black
		&_3
			background-color: $bg-white
.pop-up
	position: fixed
	top: 50vh
	left: 50vw
	transform: translate(-50%, -50%)
	width: 100vw
	max-width: 600px
	max-height: 100vh
	overflow-x: hidden
	overflow-y: auto
	&::-webkit-scrollbar
			width: 10px
	&::-webkit-scrollbar-thumb
		border-radius: 10px
		background-color: $button-color
	&::-webkit-scrollbar-track
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2)
		background-color: $bg-grey
.exit
	position: absolute
	display: flex
	justify-content: center
	align-items: center
	top: 20px
	right: 20px
	width: 48px
	height: 48px
	padding: 0
	color: $font-white
	background-color: transparent
	cursor: pointer
	transition: color 0.3s ease
	& > font
		display: block
		position: relative
		left: 1px
		width: 100%
		height: 100%
		font-size: 48px
		line-height: 44px
		text-align: center
		transform: rotate(45deg)
	&::after
		display: block
		content: ''
		position: absolute
		width: 100%
		height: 100%
		border: 2px solid $bg-white
		border-radius: 50%
		opacity: 0
		transform: scale(1.3)
		transition: all 0.3s ease
	&:hover
		&::after
			opacity: 1
			transform: scale(1)
.dropdown
	max-height: 0
	transition: max-height 0.3s
	overflow: hidden
	&--dropped
		max-height: 1500px
		transition: max-height 0.5s
		
.label-1
	display: block
	cursor: text
	margin-bottom: 20px
	font-size: 20px
	font-weight: 700
	text-transform: uppercase
.textarea-1
	display: block
	resize: none
	width: 100%
	height: 120px
	padding: 14px
	appearance: none
	border: 1px solid $font-dgrey
	border-radius: 20px
	margin-bottom: 40px
	caret-color: $font-dgrey
	font-size: 16px
	overflow: auto
	&:focus
		box-shadow: inset 0px 0px 3px 1px $button-color
.label-file
	display: block
	width: 100%
	height: 50px
	line-height: 50px
	text-align: center
	color: $bg-white
	font-size: 23px
	text-transform: uppercase
	font-weight: 900
	background-color: $button-color
	border-radius: 14px
	cursor: pointer
	@media (max-width: $max_1)
		font-size: 20px
	& > svg
		margin-right: 10px

.input-file
	display: none
.legend
	color: $bg-black
	font-size: 20px
	font-weight: 700
	text-transform: uppercase
	&_1
		margin-bottom: 20px
		cursor: pointer
		transition: all 0.3s ease
		&:hover
			// color: $gre
			text-shadow: 0 0 2px $button-color
	& > svg
		margin-right: 10px
		transform: rotate(180deg)
		transition: transform 0.3s ease
		& > path
		stroke: $button-color
	&_drop > svg
		transform: rotate(0deg)
</style>