<template lang="pug">
	form.pop-up(action='')
		fieldset.form-modal__section.form-modal__section_0
			h3.h3.form-modal__h3 {{ which === 'full-form' ? 'Анкета для новых клиентов' : 'Задайте свой вопрос' }}
			button.exit(@click.prevent='FormClose()')
				font +

		//- REQUIRED:
		formSmall(:formId='formId' :which='which' @submiting='Submiting' :FormPermission="FormPermission")

</template>

<script>
import formSmall from './formSmall'

export default {
	name: 'FormModal',
	components: {
		formSmall,
	},
	props: {
		which: String,
		formId: Number,
		FormPermission: Boolean,
	},
	data() {
		return {
			Answers: {
				title: '',
				fio: '',
				phone: '',
				mail: '',
				comment: '',
				chemZanim: '',
				vChemZadacha: '',
				reshenie: '',
				ogidanie: '',
				money: '',
				fileName: '',
				fileBase64: '',
			},
		}
	},
	methods: {
		FormClose() {
				this.$emit("formclose")
		},
		Submiting(answers) {
			this.Answers.fio = answers.fio
			this.Answers.phone = answers.phone
			this.Answers.mail = answers.mail
			this.Answers.comment = answers.comment
			this.Answers.title = 'Задать вопрос - 42SOL.RU - Модальная краткая форма'
			let param = 'FIELDS[TITLE]='+ this.Answers.title +
									'&FIELDS[NAME]=' + this.Answers.fio +
									'&FIELDS[PHONE][0][VALUE]=' + this.Answers.phone + '&FIELDS[PHONE][0][VALUE_TYPE]=WORK' +
									'&FIELDS[EMAIL][0][VALUE]=' + this.Answers.mail + '&FIELDS[EMAIL][0][VALUE_TYPE]=WORK' +
									'&FIELDS[COMMENTS]=' + this.Answers.comment +
									'&FIELDS[UF_CRM_1619698211610]=' + this.Answers.chemZanim +
									'&FIELDS[UF_CRM_1619698278919]=' + this.Answers.vChemZadacha +
									'&FIELDS[UF_CRM_1619698294896]=' + this.Answers.reshenie +
									'&FIELDS[UF_CRM_1619698307616]=' + this.Answers.ogidanie +
									'&FIELDS[UF_CRM_1619698320794]=' + this.Answers.money +
									'&FIELDS[UF_CRM_1619698360471][fileData][0]=' + this.Answers.fileName +
									'&FIELDS[UF_CRM_1619698360471][fileData][1]=' + this.Answers.fileBase64
			function restapiall(method = 'crm.lead.add', param) {
				return new Promise(function(resolve){
					let url = 'https://digitaladvance.bitrix24.ru/rest/18/ahlb6jk1qe6osqg7/'+method+'.json?'+param;
					let xhr = new XMLHttpRequest ();
					xhr.open("GET", url, true);
					xhr.onload = function(result){
						resolve(result);
					}
					xhr.send();
				})
			}
			restapiall('crm.lead.add', param).then(this.$emit("submiting"))
		}
	},
}
</script>

<style lang="sass" scoped>
@import './../../assets/styles/__variables.sass'

.form-modal
	&__h3
		color: $font-white
		margin: 0
		max-width: 90%
		font-size: 32px
		position: absolute
		bottom: 30px
		right: 30px
		text-align: right
	&__section
		width: 100%
		margin: 0
		border: none
		appearance: none
		padding: 40px 24px
		@media (max-width: $max_1)
			padding: 20px 24px
		&_0
			position: relative
			height: 190px
			background-color: $button-color
			background-repeat: no-repeat
		&_1
			background-color: $bg-white
			padding-top: 0
			padding-bottom: 50px
		&_2
			background-color: $bg-black
		&_3
			background-color: $bg-white
.pop-up
	position: fixed
	top: 50vh
	left: 50vw
	transform: translate(-50%, -50%)
	width: 100vw
	max-width: 600px
	max-height: 100vh
	overflow-x: hidden
	overflow-y: auto
	&::-webkit-scrollbar
			width: 10px
	&::-webkit-scrollbar-thumb
		border-radius: 10px
		background-color: $button-color
	&::-webkit-scrollbar-track
		-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2)
		background-color: $bg-grey
.exit
	position: absolute
	display: flex
	justify-content: center
	align-items: center
	top: 20px
	right: 20px
	width: 48px
	height: 48px
	padding: 0
	color: $font-white
	background-color: transparent
	cursor: pointer
	transition: color 0.3s ease
	& > font
		display: block
		position: relative
		left: 1px
		width: 100%
		height: 100%
		font-size: 48px
		line-height: 44px
		text-align: center
		transform: rotate(45deg)
	&::after
		display: block
		content: ''
		position: absolute
		width: 100%
		height: 100%
		border: 2px solid $bg-white
		border-radius: 50%
		opacity: 0
		transform: scale(1.3)
		transition: all 0.3s ease
	&:hover
		&::after
			opacity: 1
			transform: scale(1)
.dropdown
	max-height: 0
	transition: max-height 0.3s
	overflow: hidden
	&--dropped
		max-height: 1500px
		transition: max-height 0.5s
		
.label-1
	display: block
	cursor: text
	margin-bottom: 20px
	font-size: 20px
	font-weight: 700
	text-transform: uppercase
.textarea-1
	display: block
	resize: none
	width: 100%
	height: 120px
	padding: 14px
	appearance: none
	border: 1px solid $font-dgrey
	border-radius: 20px
	margin-bottom: 40px
	caret-color: $font-dgrey
	font-size: 16px
	overflow: auto
	&:focus
		box-shadow: inset 0px 0px 3px 1px $button-color
.label-file
	display: block
	width: 100%
	height: 50px
	line-height: 50px
	text-align: center
	color: $bg-white
	font-size: 23px
	text-transform: uppercase
	font-weight: 900
	background-color: $button-color
	border-radius: 14px
	cursor: pointer
	@media (max-width: $max_1)
		font-size: 20px
	& > svg
		margin-right: 10px

.input-file
	display: none
.legend
	color: $bg-black
	font-size: 20px
	font-weight: 700
	text-transform: uppercase
	&_1
		margin-bottom: 20px
		cursor: pointer
		transition: all 0.3s ease
		&:hover
			// color: $gre
			text-shadow: 0 0 2px $button-color
	& > svg
		margin-right: 10px
		transform: rotate(180deg)
		transition: transform 0.3s ease
		& > path
		stroke: $button-color
	&_drop > svg
		transform: rotate(0deg)

</style>