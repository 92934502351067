<template lang="pug">
	.common-comp.advs-comp
		.common-container.advs-comp-container-1
			.flies
				.flies__fly.flies__fly_3
					<svg width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M37.1896 9.71732L11.8548 0.382146C11.4815 0.244599 11.0688 0.260975 10.7076 0.427671C10.3464 0.594366 10.0662 0.897727 9.92864 1.27102L0.593473 26.6059C0.455926 26.9792 0.472302 27.3918 0.638997 27.753C0.805692 28.1142 1.10905 28.3944 1.48234 28.532L26.8172 37.8672C27.1905 38.0047 27.6031 37.9883 27.9643 37.8216C28.3256 37.6549 28.6058 37.3516 28.7433 36.9783L38.0785 11.6434C38.216 11.2701 38.1996 10.8575 38.033 10.4963C37.8663 10.1351 37.5629 9.85487 37.1896 9.71732ZM26.4469 34.5335L3.92708 26.2356L12.225 3.71575L34.7449 12.0137L26.4469 34.5335Z" fill="#00B6F0"/>
					</svg>
				.flies__fly.flies__fly_4
					<svg width="72" height="68" viewBox="0 0 72 68" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M21.9352 67.8848L25.5957 11.9761L71.4156 44.2208L21.9352 67.8848ZM66.1756 43.7489L27.9138 16.8231L24.8571 63.5096L66.1756 43.7489Z" fill="#112D57"/>
					</svg>
				.flies__fly.flies__fly_5
					<svg width="51" height="53" viewBox="0 0 51 53" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M39.996 7.97929L47.3639 5.57607L48.9661 10.4881L41.5981 12.8913L44.0013 20.2592L39.0893 21.8614L36.6861 14.4934L29.3181 16.8966L27.716 11.9847L35.084 9.58143L32.6808 2.21346L37.5927 0.611315L39.996 7.97929ZM43.8958 36.5973L41.4926 29.2294L46.4045 27.6272L50.4099 39.9072L11.114 52.7243L0.700091 20.7964L20.348 14.3879L21.9502 19.2999L7.21422 24.1063L14.4239 46.2102L43.8958 36.5973Z" fill="#00B6F0"/>
					</svg>
			.common-container.advs-comp-container-2
				h3.h3.advs-comp__h3._onscroll Преимущества
				.advs-grid
					article.advs-grid__item._onscroll(
						v-for="(card, i) in AdvsArticles"
						:key="card.id"
					)
						.advs-grid__header
							.advs-grid__ico(:class="`advs-grid__ico_${i}`")
							h4.h4.advs-grid__name {{ card.name }}
						p.txt.advs-grid__desc {{ card.desc }}
				.flies__fly.flies__fly_bottom
					<svg width="72" height="68" viewBox="0 0 72 68" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M21.9352 67.8848L25.5957 11.9761L71.4156 44.2208L21.9352 67.8848ZM66.1756 43.7489L27.9138 16.8231L24.8571 63.5096L66.1756 43.7489Z" fill="#112D57"/>
					</svg>
</template>

// =========================================================================

<script>
export default {
	name: 'Advs',
	data() {
		return {
			AdvsArticles: [
				{
					id: 1,
					name: 'Точные сроки',
					desc: 'Ваши проекты будут выполнены точно в срок, указанный при составлении ТЗ.',
				},
				{
					id: 2,
					name: 'Внимание к деталям',
					desc: 'Создаем и развиваем проекты реально решающие проблемы наших клиентов.',
				},
				{
					id: 3,
					name: 'Программисты уровня middle и senior',
					desc: 'Наличие у всех программистов сертификатов, прохождение экзаменов от 1С-Битрикс и опыт работы от 3 лет.',
				},
				{
					id: 4,
					name: '20 штатных сотрудников',
					desc: 'Для полного цикла разработки проектов. Аналитики, дизайнеры, frontend-разработчики, программисты.',
				},
				{
					id: 5,
					name: 'Прозрачная стоимость',
					desc: 'Подробная калькуляция всех этапов и элементов разработки.',
				},
				{
					id: 6,
					name: 'Свои уникальные разработки',
					desc: 'Система лояльности на базе Битрикс24, PIM система для сайтов.',
				},
			],
		}
	}
}
</script>

// =========================================================================

<style scoped lang="sass">
//VARIABLES
@import './../assets/styles/__variables.sass'

.advs-comp
	&-container-1
		position: relative
		z-index: 1
	&-container-2
		position: relative
		z-index: 2
	&__h3
		color: $font-white
		margin-bottom: 60px
		@media (max-width: $max_1)
			margin-bottom: 40px
.advs-grid
	display: grid
	grid-template-columns: 1fr 1fr 1fr
	grid-column-gap: 80px
	grid-row-gap: 50px
	@media (max-width: $max_1)
		grid-row-gap: 30px

	&__item
	&__header
		display: flex
		justify-content: flex-start
		align-items: center
	&__ico
		min-width: 45px
		width: 45px
		height: 45px
		margin-right: 10px
		border-radius: 50%
		background-position: center
		background-repeat: no-repeat
		&_0
			background-color: #EB5757
			background-image: url('./../assets/images/ico-adv-1.svg')
		&_1
			background-color: #219653
			background-image: url('./../assets/images/ico-adv-2.svg')
		&_2
			background-color: #FC3B6C
			background-image: url('./../assets/images/ico-adv-3.svg')
		&_3
			background-color: #BB6BD9
			background-image: url('./../assets/images/ico-adv-4.svg')
		&_4
			background-color: #00B6F0
			background-image: url('./../assets/images/ico-adv-5.svg')
		&_5
			background-color: #F78222
			background-image: url('./../assets/images/ico-adv-6.svg')
	&__name
		color: $font-white
		margin: 0
	&__desc
		color: $font-lgrey
		margin-bottom: 0
.flies
	&__fly
		position: absolute
		&_3
			top: -90px
			left: 10px
			@media (max-width: $max_1)
				top: -60px
		&_4
			top: -90px
			left: 25%
			@media (max-width: $max_1)
				display: none
		&_5
			top: -50px
			right: 10px
		&_bottom
			display: none
			bottom: -60px
			left: 40%
			@media (max-width: $max_1)
				display: block

// @media (min-width: $min_4) and (max-width: $max_4)
// @media (min-width: $min_3) and (max-width: $max_3)
@media (min-width: $min_2) and (max-width: $max_2)
	.advs-comp
		&__h3
	.advs-grid
		grid-template-columns: 1fr 1fr
		&__item
		&__header
		&__ico
		&__name
		&__desc
@media (max-width: $max_1)
	.advs-comp
		&__h3
	.advs-grid
		grid-template-columns: 1fr
		&__item
		&__header
		&__ico
		&__name
		&__desc
</style>