<template lang="pug">
	.common-comp.main-comp._onscroll
		.common-container.main-comp-container-1
			.flies
				.flies__fly.flies__fly_1
					<svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M34.3359 7.82451V0.124512H26.4026V2.87451H8.26927V0.124512H0.335938V7.82451H3.16927V25.4245H0.335938V33.1245H8.26927V30.3745H26.4026V33.1245H34.3359V25.4245H31.5026V7.82451H34.3359ZM28.6693 2.32451H32.0693V5.62451H28.6693V2.32451ZM2.6026 5.62451V2.32451H6.0026V5.62451H2.6026ZM6.0026 30.9245H2.6026V27.6245H6.0026V30.9245ZM32.0693 27.6245V30.9245H28.6693V27.6245H32.0693ZM29.2359 23.2245V25.4245H26.4026V28.1745H8.26927V25.4245H5.43594V7.82451H8.26927V5.07451H26.4026V7.82451H29.2359V23.2245Z" fill="#F3CD03"/>
						<path d="M16.1807 11.0156L12.3877 20.6246H14.5047L15.2357 18.7726H19.4367L20.1677 20.6246H22.2847L18.4917 11.0156H16.1807ZM16.0117 16.8036L17.3357 13.4506L18.6597 16.8036H16.0117Z" fill="#F3CD03"/>
					</svg>
				.flies__fly.flies__fly_2
					<svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="30.7852" cy="30.573" r="28" stroke="#F3CD03" stroke-width="5"/>
					</svg>
		.common-container.main-comp-container-2
			.main-comp__lazy
				h2.h2.main-comp__h2 Разработка и продвижение сайтов любой сложности
				p.abitsm-txt.main-comp__desc Лэндинги, корпоративные сайты, интернет-магазины «под ключ» (включая интеграцию с 1С), проекты highload-класса
				button.common-btn.main-comp__btn(
					@click="$emit('openform', 'full-form')"
				) Оценить проект
			.main-comp__imgs
				img(src="./../assets/images/main__img_1.jpg" alt="иллюстрация").main-comp__img.main-comp__img_1._ontime-appear
				img(src="./../assets/images/main__img_2.jpg" alt="иллюстрация").main-comp__img.main-comp__img_2._ontime-appear
				img(src="./../assets/images/main__img_3.jpg" alt="иллюстрация").main-comp__img.main-comp__img_3._ontime-appear
</template>

// =========================================================================

<script>
export default {
	name: 'Main',
}
</script>

// =========================================================================

<style scoped lang="sass">
//VARIABLES
@import './../assets/styles/__variables.sass'

.main-comp
	&-container-1
		position: relative
		z-index: 1
		height: 100%
	&-container-2
		position: relative
		z-index: 2
	&__lazy
		position: relative
		z-index: 20
	&__h2
		max-width: 70%
	&__desc
		max-width: 50%
		font-weight: 700
		color: $font-dgrey
		margin: 20px 0 40px 0
	&__btn
	&__imgs
		position: relative
		z-index: 10
		bottom: 0
		right: 0
		
	&__img
		position: absolute
		background-color: $bg-grey
		box-shadow: 25px 25px 30px 10px rgba(0, 0, 0, 0.15)
		transform: skew(-60deg, 30deg)
		&_1
			width: 200px
			height: 100px
			top: -150px
			right: 0px
			z-index: 30
		&_2
			width: 400px
			height: 170px
			top: -50px
			right: 40px
			z-index: 20
		&_3
			width: 400px
			height: 150px
			top: 50px
			right: 280px
			z-index: 10

.flies
	&__fly
		position: absolute
		&_1
			top: 360px
			left: 110px
			@media (max-width: $max_1)
				top: 100px
				left: auto
				right: 10px
		&_2
			top: 470px
			left: 150px
			@media (max-width: $max_1)
				top: 370px
				left: 40px

@media (min-width: $min_5)
	.main-comp
		&__h2
		&__desc
		&__btn
@media (min-width: $min_4) and (max-width: $max_4)
	.main-comp
		&__h2
		&__desc
		&__btn
@media (min-width: $min_3) and (max-width: $max_3)
	.main-comp
		&__h2
		&__desc
		&__btn
	
@media (min-width: $min_2) and (max-width: $max_2)
	.main-comp
		&__h2
			max-width: 100%
		&__desc
		&__btn
		&__imgs
		&__img
			transform: skew(-40deg, 20deg)
			&_1
				width: 200px
				height: 100px
				top: -120px
				right: 0px
			&_2
				width: 400px
				height: 170px
				top: -50px
				right: 40px
			&_3
				width: 400px
				height: 150px
				top: 80px
				right: 240px
@media (max-width: $max_1)
	.main-comp
		&__h2
			max-width: 100%
		&__desc
			max-width: 100%
		&__btn
			width: 100%
			max-width: 320px
			margin: 0 auto
		&__imgs
			position: absolute
			width: 100%
			bottom: -330px
			height: 330px
			display: flex
			justify-content: center
			align-items: center
			flex-direction: column
		&__img
			position: relative
			transform: skew(-40deg, 20deg)
			&_1
				width: 200px
				height: 100px
				top: 30px
				right: -20%
			&_2
				width: 250px
				height: 125px
				top: 10px
				right: -5%
			&_3
				width: 200px
				height: 75px
				top: auto
				right: 10%
</style>
